<template>
  <div style="padding-top: 5px">
    <ContactTableHeader />
    <ContactTable />
    <ContactPagination />
  </div>
</template>

<script>
import ContactTable from './ContactTable';
import ContactTableHeader from './ContactTableHeader';
import ContactPagination from './ContactPagination';
export default {
  name: 'ContactContainer',
  components: {
    ContactPagination,
    ContactTableHeader,
    ContactTable,
  },
};
</script>
