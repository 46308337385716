<template>
  <FilterHeader />
</template>

<script>
import FilterHeader from '../common/FilterHeader';
export default {
  name: 'ContactFilterHeader',
  components: { FilterHeader },
};
</script>
