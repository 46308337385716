<template>
  <TableHeader :title="'联系人管理'" :icon="'el-icon-phone'" />
</template>

<script>
import TableHeader from '../common/TableHeader';
export default {
  name: 'ContactTableHeader',
  components: { TableHeader },
};
</script>
