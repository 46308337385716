<template>
  <div>
    <ContactFilterHeader />
    <Table :column-config="columnConfig" :table-data="tableData" />
  </div>
</template>

<script>
import Table from '../common/Table';
import ContactFilterHeader from './ContactFilterHeader';
export default {
  name: 'ContactTable',
  components: {
    Table,
    ContactFilterHeader,
  },
  data() {
    return {
      columnConfig: [
        { label: '姓名', prop: 'name' },
        { label: '客户名称', prop: 'customer' },
        { label: '手机', prop: 'phone' },
        { label: '电话', prop: 'tel' },
        { label: '电子邮箱', prop: 'email' },
        { label: '是否关键决策人', prop: 'important' },
        { label: '职务', prop: 'position' },
        { label: '性别', prop: 'gender' },
        { label: '地址', prop: 'address' },
        { label: '下次联系时间', prop: 'last' },
        { label: '备注', prop: 'note' },
        { label: '创建人', prop: 'creator' },
        { label: '创建时间', prop: 'creat_at' },
      ],
      tableData: [
        {
          name: 'hwz',
          customer: '摩登服饰销售1',
          phone: '13592256275',
          tel: '--',
          email: '--',
          important: '--',
          position: '--',
          gender: '男',
          address: '--',
          last: '--',
          note: '--',
          creator: '悟空',
          create_at: '2021-01-27 13:31:57',
        },
        {
          name: 'hwz',
          customer: '摩登服饰销售2',
          phone: '13592256275',
          tel: '--',
          email: '--',
          important: '--',
          position: '--',
          gender: '男',
          address: '--',
          last: '--',
          note: '--',
          creator: '悟空',
          create_at: '2021-01-27 13:31:57',
        },
        {
          name: 'hwz',
          customer: '摩登服饰销售3',
          phone: '13592256275',
          tel: '--',
          email: '--',
          important: '--',
          position: '--',
          gender: '男',
          address: '--',
          last: '--',
          note: '--',
          creator: '悟空',
          create_at: '2021-01-27 13:31:57',
        },
        {
          name: 'hwz',
          customer: '摩登服饰销售',
          phone: '13592256275',
          tel: '--',
          email: '--',
          important: '--',
          position: '--',
          gender: '男',
          address: '--',
          last: '--',
          note: '--',
          creator: '悟空',
          create_at: '2021-01-27 13:31:57',
        },
        {
          name: 'hwz',
          customer: '摩登服饰销售',
          phone: '13592256275',
          tel: '--',
          email: '--',
          important: '--',
          position: '--',
          gender: '男',
          address: '--',
          last: '--',
          note: '--',
          creator: '悟空',
          create_at: '2021-01-27 13:31:57',
        },
        {
          name: 'hwz',
          customer: '摩登服饰销售',
          phone: '13592256275',
          tel: '--',
          email: '--',
          important: '--',
          position: '--',
          gender: '男',
          address: '--',
          last: '--',
          note: '--',
          creator: '悟空',
          create_at: '2021-01-27 13:31:57',
        },
        {
          name: 'hwz',
          customer: '摩登服饰销售',
          phone: '13592256275',
          tel: '--',
          email: '--',
          important: '--',
          position: '--',
          gender: '男',
          address: '--',
          last: '--',
          note: '--',
          creator: '悟空',
          create_at: '2021-01-27 13:31:57',
        },
        {
          name: 'hwz',
          customer: '摩登服饰销售',
          phone: '13592256275',
          tel: '--',
          email: '--',
          important: '--',
          position: '--',
          gender: '男',
          address: '--',
          last: '--',
          note: '--',
          creator: '悟空',
          create_at: '2021-01-27 13:31:57',
        },
        {
          name: 'hwz',
          customer: '摩登服饰销售',
          phone: '13592256275',
          tel: '--',
          email: '--',
          important: '--',
          position: '--',
          gender: '男',
          address: '--',
          last: '--',
          note: '--',
          creator: '悟空',
          create_at: '2021-01-27 13:31:57',
        },
        {
          name: 'hwz',
          customer: '摩登服饰销售',
          phone: '13592256275',
          tel: '--',
          email: '--',
          important: '--',
          position: '--',
          gender: '男',
          address: '--',
          last: '--',
          note: '--',
          creator: '悟空',
          create_at: '2021-01-27 13:31:57',
        },
        {
          name: 'hwz',
          customer: '摩登服饰销售',
          phone: '13592256275',
          tel: '--',
          email: '--',
          important: '--',
          position: '--',
          gender: '男',
          address: '--',
          last: '--',
          note: '--',
          creator: '悟空',
          create_at: '2021-01-27 13:31:57',
        },
        {
          name: 'hwz',
          customer: '摩登服饰销售',
          phone: '13592256275',
          tel: '--',
          email: '--',
          important: '--',
          position: '--',
          gender: '男',
          address: '--',
          last: '--',
          note: '--',
          creator: '悟空',
          create_at: '2021-01-27 13:31:57',
        },
      ],
    };
  },
};
</script>
